import Link from "next/link";
import styles from "./BlogCard.module.scss";
import Image from "next/image";
import cn from "clsx";
import dayjs from "dayjs";

export default function BlogCard({
  Heading,
  url_key,
  BannerImage,
  publishedAt,
  enabled: _enabled,
  ReadTimeText,
  PrimaryCategory,
  isBlogList = false,
}: any) {
  const img1 = BannerImage?.data?.attributes; 
  const provider = ReadTimeText?.text;

  return (
    <Link
      href={url_key ? `/${url_key}` :  "#"}
      scroll={Boolean(url_key)}
      target="_self"
      className={`block border-[0.0625rem] border-blu rounded-2xl lg:rounded-[0.833rem] ${styles.root}`}
      prefetch={false}
    >
      <div
        className={cn("flex flex-col justify-end", styles.content, {
          [styles.gradient]: isBlogList,
        })}
      >
        <Image
          src={img1?.url}
          alt={img1?.alternativeText ||''}
          className="object-cover lg:rounded-[0.833rem]"
          fill
        />
        {PrimaryCategory && isBlogList && (
          <p className={cn(styles.strip)}>
            {PrimaryCategory?.split("_")?.join(" ")}
          </p>
        )}
      </div>
      <div className={`font-raleway ${styles.bottom}`}>
        <div className={`font-bold flex items-center ${styles.heading}`}>
          {dayjs(publishedAt).format("MMM DD, YYYY")} . 
          <span  className={`homeSprite mx-1 ${styles.clockIcon}`}></span>
          {provider}
        </div>
        <div className={`font-semibold	 ${styles.text}`}>{Heading}</div>
        <div className={`homeSprite ${styles.arrow} ${styles.blogArrow}`}></div>
      </div>
    </Link>
  );
}
