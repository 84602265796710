import(/* webpackMode: "eager" */ "/home/ubuntu/frontend/node_modules/@apollo/experimental-nextjs-app-support/dist/ssr/ApolloNextAppProvider.js");
import(/* webpackMode: "eager" */ "/home/ubuntu/frontend/node_modules/@apollo/experimental-nextjs-app-support/dist/ssr/hooks.js");
import(/* webpackMode: "eager" */ "/home/ubuntu/frontend/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/home/ubuntu/frontend/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/home/ubuntu/frontend/src/components/atoms/Button/index.tsx");
import(/* webpackMode: "eager" */ "/home/ubuntu/frontend/src/components/common/Analytics/CustomButton.tsx");
import(/* webpackMode: "eager" */ "/home/ubuntu/frontend/src/components/molecules/Carousels/Carousel.tsx");
import(/* webpackMode: "eager" */ "/home/ubuntu/frontend/src/components/molecules/SectionCard/SectionCard.module.scss");
import(/* webpackMode: "eager" */ "/home/ubuntu/frontend/src/components/molecules/Carousels/HeroCarousel/HeroCarousel.module.scss");
import(/* webpackMode: "eager" */ "/home/ubuntu/frontend/src/components/molecules/PageCard/PageCard.module.scss");
import(/* webpackMode: "eager" */ "/home/ubuntu/frontend/src/components/organisms/SimilarProgramsSection/SimilarProgramsSection.tsx");
import(/* webpackMode: "eager" */ "/home/ubuntu/frontend/src/components/organisms/StudentTestimonials/StudentTestimonials.tsx");
import(/* webpackMode: "eager" */ "/home/ubuntu/frontend/src/components/sections/BannerCtaWithDesc/style.module.scss");
import(/* webpackMode: "eager" */ "/home/ubuntu/frontend/src/components/sections/BannerCard/BannerCard.module.scss");
import(/* webpackMode: "eager" */ "/home/ubuntu/frontend/src/components/sections/BlogList/index.tsx");
import(/* webpackMode: "eager" */ "/home/ubuntu/frontend/src/components/sections/BuildNation/BuildNation.module.scss");
import(/* webpackMode: "eager" */ "/home/ubuntu/frontend/src/components/sections/FacultyCarousel/index.tsx");
import(/* webpackMode: "eager" */ "/home/ubuntu/frontend/src/components/sections/FaqSection/Faq.module.scss");
import(/* webpackMode: "eager" */ "/home/ubuntu/frontend/src/components/sections/NewLists/index.tsx");
import(/* webpackMode: "eager" */ "/home/ubuntu/frontend/src/components/sections/PagesCards/PagesCards.module.scss");
import(/* webpackMode: "eager" */ "/home/ubuntu/frontend/src/components/sections/WhyAmityCard/WhyAmity.module.scss");
import(/* webpackMode: "eager" */ "/home/ubuntu/frontend/src/components/sections/AccreditionsRecognitions/AccreditionsRecognitions.module.scss");
import(/* webpackMode: "eager" */ "/home/ubuntu/frontend/src/components/sections/VideoSection/VideoSection.module.scss");
import(/* webpackMode: "eager" */ "/home/ubuntu/frontend/src/components/templates/Faq.tsx")